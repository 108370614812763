@import url('https://cdn.syncfusion.com/ej2/material.css');

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media  screen and (max-width:800px) {
  .sidebar{
    z-index: 10000000;
  }
}

.e-dlg-center-center, .e-quick-popup-wrapper.e-device{
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

 #preview {
  background: transparent
    url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png')
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  margin-left:20px;
  max-width: 600px;
  background-color: #008000;
}

.header{
  display :flex
}
.e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left){
  border: none;
}

/* Rich Text Viewer Styles */
.rich-text-viewer {
  padding: 1rem;
  line-height: 1.6;
  font-family: inherit;
}

/* Paragraph spacing */
.rich-text-viewer p {
  margin-bottom: 1em;
}

/* Headings spacing */
.rich-text-viewer h1,
.rich-text-viewer h2,
.rich-text-viewer h3,
.rich-text-viewer h4,
.rich-text-viewer h5,
.rich-text-viewer h6 {
  margin-top: 1.5em;
  margin-bottom: 0.75em;
}

/* Lists spacing */
.rich-text-viewer ul,
.rich-text-viewer ol {
  margin-bottom: 1em;
  padding-left: 2em;
}

/* Blockquotes */
.rich-text-viewer blockquote {
  margin: 1em 0;
  padding-left: 1em;
  border-left: 3px solid #eee;
}

/* Images */
.rich-text-viewer img {
  max-width: 100%;
  height: auto;
  margin: 1em 0;
}

/* Tables */
.rich-text-viewer table {
  border-collapse: collapse;
  width: 100%;
  margin: 1em 0;
}

.rich-text-viewer table td,
.rich-text-viewer table th {
  padding: 0.5em;
  border: 1px solid #ddd;
}

/* Code blocks */
.rich-text-viewer pre {
  background: #f5f5f5;
  padding: 1em;
  border-radius: 4px;
  overflow-x: auto;
  margin: 1em 0;
}

/* Horizontal rule */
.rich-text-viewer hr {
  margin: 2em 0;
  border: 0;
  border-top: 1px solid #eee;
}