@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

body{
    margin: 0;
    padding:0;
    font-family: "Open Sans", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-slide > div {
    margin: 0 10px;
  }
  .custom-black-icon path {
    fill: black !important;
  }
  
  